<template>
  <div>
    <el-form
      ref="nodeForm"
      :rules="rules"
      label-position="top"
      label-width="1000px"
      :model="nodeToBind"
      :hide-required-asterisk="false"
    >
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item prop="node_name" :label="__('Name')">
            <el-input v-model="nodeToBind.node_name" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" style="padding-top: 10px">
        <el-col :span="24">
          <el-form-item
            :label="__('Assign Summary to Variable')"
            prop="summary_var"
            class="is-required"
          >
            <variable-dropdown
              :placeholder="__('Select or create a new variable')"
              :new-item-message="__('new variable')"
              :studioVariables="singleValuedVariables"
              :currentSelect="currentSelection(insertedSummaryVariableRule)"
              :variableRule="summaryVariableRuleValue"
              @updateVariables="handleVariableUpdate"
              :nodeData="nodeToBind.summarization_node"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" style="padding-top: 15px">
        <el-col :span="24">
          <el-form-item
            :label="__('Assign Conversation Transcript to Variable')"
          >
            <variable-dropdown
              :placeholder="__('Select or create a new variable')"
              :new-item-message="__('new variable')"
              :studioVariables="singleValuedVariables"
              :currentSelect="currentSelection(insertedTranscriptVariableRule)"
              :variableRule="transcriptVariableRuleValue"
              @updateVariables="handleVariableUpdate"
              :nodeData="nodeToBind.summarization_node"
            />
          </el-form-item>
          <el-form-item :label="__('Summary Language')">
            <input-variable-popper
              :value="
                nodeToBind.summarization_node.data.summary_language ||
                  'English US'
              "
              @input="updateSummaryLanguage"
              :is-text-area="false"
              is-auto-complete-input
              :autocomplete-list="autocompleteLanguageList"
              :placeholder="__('Select Summary Language')"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import BaseNode from "../BaseNode";
import _ from "lodash";
import { NODE_TYPES } from "@/constants/nodes";
import NodeUsesVariableDropdown from "@/views/build/callflow/components/node-type-forms/NodeUsesVariableDropdown.vue";
import { mapGetters } from "vuex";
import InputVariablePopper from "@/views/build/callflow/components/node-type-forms/components/InputVariablePopper.vue";
import { aiSummaryLanguageList } from "@/constants/openFormType";
import VariableDropdown from "@/views/build/callflow/components/node-type-forms/components/VariableDropdown.vue";

const variableRuleInitialize = {
  rule_value: "",
  variable_name: "",
  variable_id: -1,
  default_value: "",
  array_variable: false
};

export default {
  mixins: [BaseNode, NodeUsesVariableDropdown],
  components: {
    VariableDropdown,
    InputVariablePopper
  },
  data() {
    const validateSummaryField = async (rule, value, callback) => {
      if (!this.currentSelection(this.insertedSummaryVariableRule)) {
        callback(new Error(__("Summary Variable Required")));
      }
      callback();
    };

    return {
      variableRuleInitialize: _.cloneDeep(variableRuleInitialize),
      summaryVariableRuleValue: "open_ai_summary_var",
      transcriptVariableRuleValue: "open_ai_transcript_var",
      autocompleteLanguageList: aiSummaryLanguageList,
      rules: {
        summary_var: {
          validator: validateSummaryField,
          trigger: "submit"
        }
      },
      nodeName: "summarization_node"
    };
  },
  computed: {
    ...mapGetters("variables", {
      singleValuedVariables: "singleValuedVariables"
    }),

    isNewVariableCreated() {
      return !!_.find(
        this.nodeToBind.summarization_node.data.variable_rules.data,
        rule => rule.variable_id === -1
      );
    },
    /**
     * returns selected variable rule for summary variable rule if set, otherwise returns initial variable rule structure
     * @return object
     */
    insertedSummaryVariableRule() {
      return (
        _.find(
          this.nodeToBind.summarization_node.data.variable_rules.data,
          rule => rule.rule_value === this.summaryVariableRuleValue
        ) ||
        _.find(
          this.nodeToBind.summarization_node.data.interaction_variable_rules
            .data,
          rule => rule.rule_value === this.summaryVariableRuleValue
        ) ||
        this.variableRuleInitialize
      );
    },
    /**
     * returns selected variable rule for transcript variable rule if set, otherwise returns initial variable rule structure
     * @return object
     */
    insertedTranscriptVariableRule() {
      return (
        _.find(
          this.nodeToBind.summarization_node.data.variable_rules.data,
          rule => rule.rule_value === this.transcriptVariableRuleValue
        ) ||
        _.find(
          this.nodeToBind.summarization_node.data.interaction_variable_rules
            .data,
          rule => rule.rule_value === this.transcriptVariableRuleValue
        ) ||
        variableRuleInitialize
      );
    }
  },
  methods: {
    cleanUpNodeToPrepareForSubmit() {
      return _.cloneDeep(this.nodeToBind);
    },
    cleanUpNode() {
      if (!this.formHasErrors) {
        this.nodeToBind = this.cleanUpNodeToPrepareForSubmit();
        this.newVariableCreated = this.isNewVariableCreated;
        this.createOrEditNode();
      } else {
        this.toggleNodeSubmit(false);
      }
    },
    updateSummaryLanguage(language) {
      this.$set(
        this.nodeToBind.summarization_node.data,
        "summary_language",
        language
      );
    }
  },
  created() {
    if (
      !this.nodeToBind.node_id ||
      _.isEmpty(this.nodeToBind.summarization_node)
    ) {
      this.$set(this.nodeToBind, "summarization_node", {});
      this.$set(this.nodeToBind.summarization_node, "data", {});

      if (_.isEmpty(this.nodeToBind.summarization_node.data.variable_rules)) {
        this.$set(
          this.nodeToBind.summarization_node.data,
          "variable_rules",
          {}
        );
        this.$set(
          this.nodeToBind.summarization_node.data.variable_rules,
          "data",
          []
        );
      }
      if (
        _.isEmpty(
          this.nodeToBind.summarization_node.data.interaction_variable_rules
        )
      ) {
        this.$set(
          this.nodeToBind.summarization_node.data,
          "interaction_variable_rules",
          {}
        );
        this.$set(
          this.nodeToBind.summarization_node.data.interaction_variable_rules,
          "data",
          []
        );
      }
      this.$set(
        this.nodeToBind,
        "node_type",
        NODE_TYPES.SUMMARIZATION.NODE_TYPE
      );
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/node-palette.scss";
@import "~@/styles/element-variables.scss";

::v-deep .row-message {
  font-size: 0.75rem;
  color: $--color-success;
  margin-left: 10px;
}

.el-form-item:last-of-type {
  margin-bottom: 10px;
}
</style>
